@font-face {
    font-family: 'DIN Pro';
    src: url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro-Bold.woff2?v=1718357061') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro-Bold.woff?v=1718357061') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Pro';
    src: url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro-Medium.woff2?v=1718357061') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro-Medium.woff?v=1718357061') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'DIN Pro';
    src: url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro.woff2?v=1718357060') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/DINPro.woff?v=1718357061') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Space Grotesk';
    src: url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/SpaceGrotesk-Bold.woff2?v=1718357701') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0442/3276/9690/files/SpaceGrotesk-Bold.woff?v=1718357701') format('woff');
    font-weight: bold;
    font-style: normal;
}
.mosaic-header {
  text-align: center;
  padding-bottom: 30px;
}
.mosaic-heading {
  margin-top: 12px; 
}
.mosaic-header p {
  margin: 0;
}
.navlink--toplevel .navtext {
  font-size: 16px;
}
.nav .icon-location svg {
  stroke-width: 5px !important;
  height: 23px;
}
.icons-row__text p {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.cart__message__default {
  font-weight: 700;
}
.cart-drawer__empty-message > p {
  font-weight: 700;
}
.radio__fieldset .radio__label {
  border-radius: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-color: #000 !important;
}
.radio__legend__label {
  font-weight: 700;
}
.product__accordion__title {
  font-weight: 700;
  font-size: 14px;
}
.alt-product-link {
  display: inline-block;
  border: 1px solid #000;
  margin: 0 12px 12px 0;
}

.product__icon__text:not(:only-child) a {
  border-bottom: 2px solid #000;
}
.product-single__media img {
  filter: brightness(.97);
}
.press__item p {
  font-weight: 700;
  font-family: var(--FONT-STACK-HEADING);
}
.mosaic__item__subheading {
  font-size: 16px;
}
.locations__info-heading {
  font-size: 40px;
}
.collection-alternates img {
  width: 30px;
  display: block;
}
.collection-alternates .alt-product-link {
  height: auto;
  margin: 0 6px 3px 0;
}
.alternate__products img {
  display: block;
}
.shirt-promo {
  color: #c31718;
  background-color: rgba(195, 23, 24, 0.1);
  padding: 0 10px;
  margin: 0 20px;
  display: inline-block;
  font-weight: 700;
}
.empty-collection-look .product-grid-item {
  width: 100%;
}
.empty-collection-look {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
}
.swiper {
  width: 100%;
  margin-top: 25px;
  padding-bottom: 25px !important;
}
.popup-quick-view__body .product__form {
  gap: 0;
}
.popup-quick-view__body .product__selectors {
  row-gap: 0;
}
.inner-slide {
  display: flex;
  background-color: #000;
  color: #fff;
  align-items: center;
  padding: 22px 13px;
  border-radius: 8px;
  min-height: 111px;
}
.inner-slide p {
  margin: 0;
  padding-bottom: 15px;
  font-size: 14px;
}
.left-slide {
  flex-basis: 25%;
}
.left-slide img {
  width: 100%;
  display: block;
}
.right-slide {
  flex-basis: 75%;
  padding-left: 15px;
}
.right-slide a {
  display: inline;
  color: #fff;
  --btn-text: #fff;
  font-size: 14px;
  font-weight: 700;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0 !important;
  top: unset !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #333 !important;
  background-color: transparent !important;
  transition: .4s !important;
}
.swiper-pagination-bullet-active {
  width: 25px !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  border: 1px solid #333 !important;
  height: 10px !important;
}
.product__form__errors p {
  margin-top: 0;
}
.product-single__details .product__price {
  font-size: 22px;
  font-weight: 700;
}
.with-sidebar {
  display: flex;
}
.help-centre-sidebar {
  max-width: 320px;
  width: 100%;
  position: sticky;
  top: 100px;
  min-width: 220px;
}
.help-centre {
  display: flex;
  padding-top: 0;
  padding-bottom: 100px;
  gap: 55px;
  align-items: flex-start;
}
.help-centre-sidebar a {
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 20px;
  background-color: #f4f4f4;
  width: 100%;
  margin-bottom: 4px;
  transition: .4s;
}
.help-centre-spacer hr {
  margin: 35px auto;
}
.help-centre-btns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 60px;
}
.square-button {
  border: 2px solid #000;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}
.square-button:before {
  content: "";
  position: absolute;
  display: flex;
  left: calc(var(--BORDER-WIDTH)* -1);
  top: calc(var(--BORDER-WIDTH)* -1);
  right: calc(var(--BORDER-WIDTH)* -1);
  bottom: calc(var(--BORDER-WIDTH)* -1);
  transform: scaleY(0);
  transform-origin: top center;
  will-change: transform;
  z-index: -1;
  transition: transform .3s ease;
  background: #F4F4F4;
}
.square-button:hover:before {
  transform-origin: bottom center;
  transform: none;
}
.action.next:before {
  content: "";
  position: absolute;
  display: flex;
  left: calc(var(--BORDER-WIDTH)* -1);
  top: calc(var(--BORDER-WIDTH)* -1);
  right: calc(var(--BORDER-WIDTH)* -1);
  bottom: calc(var(--BORDER-WIDTH)* -1);
  transform: scaleY(0);
  transform-origin: top center;
  will-change: transform;
  z-index: -1;
  transition: transform .3s ease;
  background: #F4F4F4;
  opacity: .1;
}
.action.next:hover:before {
  transform-origin: bottom center;
  transform: none;
}
.help-centre-head .help-centre-heading {
  text-align: center;
}
.help-centre-heading .h2 {
  font-size: 55px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: 110%;
  margin-bottom: 30px;
}
.help-centre-head {
  padding-top: 70px;
  padding-bottom: 70px;
}
.help-centre-subheading .h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.484px;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 'DIN Pro';
}
.accordion-menu-title {
  padding: 17px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.accordion-menu-title span {
  letter-spacing: 0.11em;
  line-height: 110%;
  letter-spacing: -0.352px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.accordion-menu-body {
  border-bottom: 1px solid var(--border);
}
.accordion-menu-body-inner {
  margin: 0;
  padding: 0 0 55px;
}
.accordion-menu-body-inner p {
  font-size: 16px;
  margin: 0;
}
.accordion-menu-title .icon-toggle-plus, .accordion-menu-title .icon-toggle-minus {
  transition: .4s;
  position: absolute;
  right: 0;
}
.accordion-menu-title .icon-toggle-plus {
  opacity: 1;
}
.accordion-menu-title.active .icon-toggle-plus {
  transform: rotate(90deg);
  opacity: 0;
}
.accordion-menu-title .icon-toggle-minus {
  opacity: 0;
  transform: rotate(-90deg);
}
.accordion-menu-title.active .icon-toggle-minus {
  opacity: 1;
  transform: rotate(0deg);
}
.accordion-menu-body {
  max-height: 0;
  overflow: hidden;
  transition: .5s;
}
.help-centre-sidebar .current-page {
  background-color: #FF4C00;
  color: #fff;
}
.icon-payments {
  display: flex;
  gap: 30px;
  justify-content: center;
}
.collection-alternates .custom-swatch {
  width: 18px;
  height: 8px;
}
.product-alternates .custom-swatch {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
}
.custom-swatch {
  overflow: hidden;
  position: relative;
}
.custom-swatch a {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.alternate__products {
  display: flex;
  gap: 6px;
}
.collection__filter__title {
  font-size: 14px;
  font-weight: bold;
}
.menu__item--icon .icon {
  transition: .4s;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.shopify-payment-button__button {
  height: auto !important;
}
.scasl-search-btn button, #scapl-geocode-btn {
  width: 30% !important;
}
#bh-sl-user-location #bh-sl-address {
  width: 65% !important;
}
#scasl-app-container {
  margin: 0 !important;
}
.bh-sl-container button {
  text-transform: uppercase;
  font-family: 'DIN Pro' !important;
}
.bh-sl-container .bh-sl-form-container {
  margin-top: 0 !important;
}
.bh-sl-container .bh-sl-map-container {
  margin-top: 10px !important;
}
#scasl-app-container #page-header {
  display: none !important;
}
.bh-sl-container .form-input, .bh-sl-container .form-input input, .bh-sl-container .form-input select,
.bh-sl-container .form-input input, .bh-sl-container .form-input select {
  border-radius: 50px !important;
}
.scasl-see-more-content {
  display: none !important;
}
.scasl-address-wrap {
  max-width: 820px;
  width: 100%;
  text-align: center;
  padding: 0 60px;
  margin: 0 auto;
}
.permission-error {
  text-align: center !important;
}
.form-input label {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  font-family: 'Space Grotesk';
  margin-bottom: 20px;
  color: #000;
}
button#scapl-geocode-btn {
  border-radius: 40px;
  height: 46px;
}
.scasl-search-btn {
  display: none !important;
}
.same-line {
  display: inline-block;
}
.product-grid-item__price-new {
  font-weight: bold;
  color: #FF4C00;
  margin-left: 5px;
}
.caps-nav .mobile-navlink--level-1 {
  text-transform: uppercase;
}
.video-product video {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 8px;
}
.product__media img, .product-upsell__image img {
  filter: brightness(.97);
}
.product-upsell__holder {
  border: 0 !important;
}
.product-upsell__btn-text {
  color: #000;
}
.product-upsell__btn:after {
  background: #000 !important;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}
.help-centre-dropdown {
  display: none;
}
.custom-dropdown svg {
  pointer-events: none;
}

.dropdown-button {
  background-color: #FF4C00;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #FF4C00;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  width: 100%;
}
.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
}
.dropdown-content .selected {
  background-color: #dcdcdc;
}
.help-btns {
  display: flex;
  gap: 20px;
}
.return-logo {
  display: flex;
}
.return-logo-img {
  max-width: 90px;
  width: 100%;
}
.padding-div {
  padding-left: 30px;
}
.help-centre-tab {
  overflow: hidden;
}
.help-centre-tab button:hover {
  background-color: #ddd;
}
.tabcontent {
  display: none;
  padding: 0;
}
.tablinks {
  opacity: .25;
  transition: .4s;
}
.tablinks.active {
  opacity: 1;
}
#pjn-popup {
  position: unset !important;
}
.pjn-popup-container {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 95% !important;
  margin: 0 auto;
  position: unset !important;
  transform: translate(0) !important;
}
.product__block:has(> #pjn-product-container) {
  position: relative;
  z-index: 9999;
}
.pjn-popup-header {
  grid-template-columns: 1fr !important;
}
.pjn-popup-header .pjn-logo {
  display: none !important;
}
.circleEnclosing {
  font-size: 8px !important;
}
#pjn-popup-trigger:after, #pjn-popup-trigger:before,
.pjn-popup-footer a:after, .pjn-popup-footer a:before,
.learn-more-both:after, .learn-more-both:before {
  display: none !important;
}
.pjn-logo svg {
  width: auto !important;
}
.pjn-container .pjn-inner {
  grid-template-columns: 1fr !important;
  grid-gap: 0 !important;
}
.pjn-inner .pjn-text p {
  padding: 0 !important;
}
.payflex-widget-wrapper {
  padding: 0 !important;
}
.payflex-widget-wrapper .flex {
  display: block !important;
}
#payflex-logo {
  width: 80px !important;
}
.payflex-widget-wrapper .ml-4 {
  margin-left: 0 !important;
}
.pjn-container .pjn-text, .payflex-widget-wrapper p,
#payflex-modal, .pjn-popup-container p, #KiwiSizingChart,
#KiwiSizingChart span, #KiwiSizingChart p {
  font-family: 'DIN Pro' !important;
}
.pjn-infolink {
  display: table;
}
.pjn-container .pjn-text {
  display: block;
}
.topics {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: -35px;
}
.radio__fieldset {
  width: 100%;
}
.dropdown__family {
  line-height: 150%;
}
.payment-options-text p {
  font-size: 14px;
  line-height: 100%;
  margin: 0;
}
.payment-options-text a {
  font-size: 14px;
}
.payflex-text {
  color: #C7A5F9;
  font-weight: 700;
}
#shopify-section-template--16857218777242__main .product__subheading p {
  font-size: 14px !important;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'DIN Pro' !important;
}
.btn.ks-chart-modal-button, span.ks-chart-modal-link {
  margin-top: 0 !important;
}
#shopify-section-template--16857218777242__main .product-upsell__title {
  font-size: 14px !important;
  font-family: 'DIN Pro' !important;
}
.product__accordion__title {
  padding-bottom: 6px;
}
.policy-page h1 {
  font-size: 40px;
}
.policy-page h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
}
.policy-page p, .policy-page li, .policy-page a {
  font-weight: 500;
}
.stofind-modal .sto-modal-options {
  display: block !important;
}
.globo-form .message.success .content {
  display: none !important;
}
.globo-form .gfb__content-title {
  margin-bottom: 0 !important;
}
.globo-form-app .message.success .gfb__content-title {
  font-weight: 100 !important;
}
#stofind-select {
  display: flex;
  align-items: center;
}
#stofind-select svg {
  width: 20px;
  height: 20px;
  stroke-width: 3px;
  margin-right: 3px;
}
.drawer--nav [data-collapsible-trigger], .drawer--nav [data-collapsible-trigger-mobile] {
  position: absolute;
  top: 0;
}
.upsell-heading {
  font-size: 14px !important;
  font-weight: 700;
  padding-top: 40px;
}
.overlapping-images__text p {
  font-size: 22px;
}
#accepts_marketing  {
  text-align: left;
  margin-left: 15px;
  margin-bottom: 10px;
}
.collection__title {
  font-size: 55px;
}
.app-flex {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}
.stofind-find-in-store-button .stofind-open-modal {
  font-weight: 500 !important;
  font-size: 16px !important;
}
.collection__filters-more {
  padding-right: 0 !important;
}

@media only screen and (max-width: 767px) {
  .collection__title {
    font-size: 35px;
  }
  .locations__info-subheading {
    margin-top: 20px;
  }
  .locations__info-heading:last-child {
    padding-bottom: 0;
  }
  .locations__info .section__header {
    margin-bottom: 16px;
  }
  .locations__info {
    padding-right: 0 !important;
    text-align: center;
  }
  .locations__info .text-left {
    text-align: center !important;
  }
  .help-centre-btns {
    margin-top: 30px;
  }
  .help-centre-head {
    padding-bottom: 16px !important;
  }
  .new-for-mobile a#scapl-geocode-btn {
    top: 0 !important;
  }
  #bh-sl-user-location #bh-sl-address {
    width: 100% !important;
  }
  .scasl-address-wrap {
    padding: 0 30px !important;
  }
  .bh-sl-address {
    font-size: 35px !important;
  }
  .form-input label {
    font-size: 35px;
  }
  .help-centre-heading .h2 {
    font-size: 35px;
  }
  .scasl-see-more {
    display: none !important;
  }
  .popup-quick-view__body .radio__legend__label {
    font-size: 14px;
    margin-top: 15px;
  }
  .h1 {
    font-size: 45px !important;
  }
  .h2 {
    font-size: 35px !important;
  }
  .locations__info-heading {
    font-size: 28px;
  }
  button#scapl-geocode-btn {
    min-width: 100%;
  }
  .popup-quick-view__form--large .popup-quick-view__title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
  .mosaic__item__content-inner .h2 {
    font-size: 28px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .grandparent .navlink--child>.navtext {
    font-size: clamp(15px, 1.2vw, 24px);
  }
  .dropdown__family {
    font-size: clamp(15px, 1.2vw, 24px);
  }
}
@media only screen and (min-width: 951px) {
  .size-chart-wrap {
    width: 54vw;
  }
}
@media only screen and (max-width: 950px) {
  .help-centre-btns {
    grid-template-columns: 1fr;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .help-centre {
    display: block;
  }
  .help-centre-sidebar {
    display: none;
  }
  .help-centre-dropdown {
    display: block;
    margin-bottom: 50px;
    border-radius: 0;
    width: 100%;
    position: sticky;
    top: 60px;
    z-index: 10;
  }
  .help-centre-html > ol {
    margin: 0;
  }
  .help-btns {
    flex-direction: column;
  }
  .help-centre-btns a {
    height: 100px;
  }
  .help-centre-btns img {
    width: 40px;
  }
  .square-button {
    padding: 0 20px;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 600px) {
  .return-logo {
    display: block;
  }
  .return-logo-img {
    margin-bottom: 25px;
  }
  .return-logo div {
    padding: 0;
  }
  .padding-div {
    padding-left: 0;
  }
  .help-centre-html a {
    width: 100%;
  }
}
@media only screen and (max-width: 1161px) {
  .tooltiptext {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .mosaic__item__heading.h2 {
    font-size: 20px !important;
  }
  .collection-promo__heading .text-highlight__break {
    font-size: 20px;
  }
}
@media (pointer: coarse) {
  .tooltiptext {
    display: none;
  }
}

.shopify-payment-button__button[disabled] {
  opacity: 0.2 !important;
}


